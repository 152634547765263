import http from "./axios";

// 查询列表
export const syncRulePage = (data) => {
  return http.get("/syncRule/syncRulePage", data);
};

// 添加
export const addData = (data) => {
  return http.json_post("/syncRule/addSyncRule", data);
};

// 编辑
export const editData = (data) => {
  return http.json_post("/syncRule/editSyncRule", data);
};

// 删除
export const deleteData = (data) => {
  return http.json_post("/syncRule/deleteSyncRule", data);
};

export const fieldConvertRulePage = (data) => {
  return http.get("/fieldConvertRule/fieldConvertRulePage", data);
};

export const addFieldConvertRule = (data) => {
  return http.json_post("/fieldConvertRule/addFieldConvertRule", data);
};

export const editFieldConvertRule = (data) => {
  return http.json_post("/fieldConvertRule/editFieldConvertRule", data);
};

export const deleteFieldConvertRule = (data) => {
  return http.json_post("/fieldConvertRule/deleteFieldConvertRule", data);
};

export const showTablesField = (data) => {
  return http.json_post("/datasource/showTablesField", data);
};

export const detail = (data) => {
  return http.get("/syncRule/detail", data);
};

export const getSyncTypeList = () => {
  return http.get("/syncRule/syncTypeList");
};
