<template>
  <a-modal
    :title="title"
    v-model="addKeyVisible"
    v-if="addKeyVisible"
    :maskClosable="false"
    class="action-class"
    width="1000px"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-row>
        <a-col :span="12">
          <a-form-item
            label="名称:"
            v-if="
              type == 'template' ||
              (this.type !== 'template' && this.title == '新增' && !this.ruleId)
            "
          >
            <a-input
              v-model="nameAdd"
              placeholder="请输入名称"
              style="width: 300px"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="原数据类型:">
            <a-select
              v-model="sourceType"
              placeholder="请选择原数据类型"
              :disabled="type !== 'template' || disabled"
              @change="getDataSourceList(null, 'source')"
              style="width: 300px"
            >
              <a-select-option
                v-for="(item, index) in sourceTypeList"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="原数据源:">
            <a-select
              v-if="sourceType !== 'KAFKA'"
              v-model="sourceDataSourceIdAdd"
              placeholder="请选择下游数据源"
              show-search
              :filter-option="false"
              @change="dataSourceChange($event, 'source')"
              @search="searchDataSourceList($event, 'source')"
              style="width: 300px"
              :disabled="disabled"
            >
              <a-select-option
                v-for="item in sourceDataSourceList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.databaseName }}({{ item.dataSourceName }})
              </a-select-option>
            </a-select>
            <a-input
              v-else
              v-model="sourceDatabaseNameAdd"
              placeholder="请输入原数据源"
              style="width: 300px"
              :disabled="disabled"
            />
            <a-icon
              type="swap-right"
              style="margin-left: 40px; font-size: 25px"
            />
          </a-form-item>
          <a-form-item label="原表名:">
            <a-select
              v-if="sourceType !== 'KAFKA' && sourceTableSelect"
              v-model="sourceTableNameAdd"
              placeholder="请选择原表名"
              :disabled="!sourceDataSourceIdAdd || disabled"
              show-search
              @change="tableNameChange($event, 'source')"
              style="width: 300px"
            >
              <a-select-option
                v-for="(item, index) in sourceTableList"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
            <a-input
              v-else
              v-model="sourceTableNameAdd"
              placeholder="请输入原表名"
              style="width: 300px"
              @pressEnter="tableNameChange(sourceTableNameAdd, 'source')"
              :disabled="!sourceDataSourceIdAdd || disabled"
            />
            <a-button
              v-if="sourceType !== 'KAFKA'"
              type="link"
              style="margin-left: 5px"
              @click="sourceTableTypeChange"
              :disabled="disabled"
            >
              {{ sourceTableSelect ? "输入" : "选择" }}
            </a-button>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="下游数据类型:">
            <a-select
              v-model="sinkType"
              placeholder="请选择下游数据类型"
              :disabled="type !== 'template' || disabled"
              @change="getDataSourceList(null, 'sink')"
              style="width: 300px"
            >
              <a-select-option
                v-for="(item, index) in sinkTypeList"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="下游数据源:">
            <a-select
              v-model="sinkDataSourceIdAdd"
              placeholder="请选择下游数据源"
              show-search
              :filter-option="false"
              @change="dataSourceChange($event, 'sink')"
              @search="searchDataSourceList($event, 'source')"
              style="width: 300px"
              :disabled="disabled"
            >
              <a-select-option
                v-for="item in sinkDataSourceList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.databaseName }}({{ item.dataSourceName }})
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="下游表名:">
            <a-select
              v-model="sinkTableNameAdd"
              placeholder="请选择下游表名"
              :disabled="!sinkDataSourceIdAdd || disabled"
              show-search
              @change="tableNameChange($event, 'sink')"
              style="width: 300px"
            >
              <a-select-option
                v-for="(item, index) in sinkTableList"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="searchCondition">
      <div class="searchButton">
        <a-button
          type="primary"
          :disabled="
            !sourceTableNameAdd ||
            !sinkTableNameAdd ||
            sinkFiledList.length == fieldMappingList.length ||
            disabled
          "
          @click="addSyncRuleFieldMapping"
        >
          添加
        </a-button>
      </div>
    </div>

    <a-table
      :rowClassName="rowClassColor"
      :columns="syncRuleFieldMappingColumns"
      :dataSource="fieldMappingList"
      :pagination="false"
      size="small"
    >
      <span slot="sourceField" slot-scope="text, record">
        <a-select
          v-if="sourceType !== 'KAFKA'"
          v-model="record.sourceField"
          placeholder="请选择原字段"
          style="width: 200px"
          show-search
          :disabled="disabled"
        >
          <a-select-option
            v-for="(item, index) in sourceFiledList"
            :key="index"
            :value="item"
            :disabled="hasSourceField(item)"
            >{{ item }}</a-select-option
          >
        </a-select>
        <a-input
          v-else
          placeholder="请输入原字段"
          v-model.trim="record.sourceField"
          style="width: 200px"
          :disabled="disabled"
        />
      </span>
      <span slot="sinkField" slot-scope="text, record">
        <a-select
          v-model="record.sinkField"
          placeholder="请选择下游字段"
          style="width: 200px"
          show-search
          :disabled="disabled"
        >
          <a-select-option
            v-for="(item, index) in sinkFiledList"
            :key="index"
            :value="item"
            :disabled="hasSinkField(item)"
            >{{ item }}</a-select-option
          >
        </a-select>
      </span>
      <span slot="uniqueIndex" slot-scope="text, record, index">
        <a-switch
          checked-children="是"
          un-checked-children="否"
          v-model="record.uniqueIndex"
          @change="uniqueIndexChange(index)"
          :disabled="disabled"
        />
      </span>
      <span slot="merge" slot-scope="text, record">
        <a-switch
          :disabled="!record.uniqueIndex || disabled"
          checked-children="是"
          un-checked-children="否"
          v-model="record.merge"
        />
      </span>
      <span slot="action" slot-scope="text, record, index" style="height: 49px">
        <a
          href="javascript:;"
          @click="setFieldConvertRule(record)"
          v-if="record.id"
          :disabled="disabled"
          >转换规则配置</a
        >
        <a-divider type="vertical" v-if="record.id" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteSyncRuleFieldMapping(index)"
          :disabled="disabled"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f" :disabled="disabled">
            删除
          </a>
        </a-popconfirm>
      </span>
    </a-table>
    <template slot="footer">
      <div style="display: flex; justify-content: center">
        <a-button key="back" @click="addKeyVisible = false">
          {{ disabled ? "关闭" : "取消" }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="add_submit"
          v-if="!disabled"
          >确定</a-button
        >
      </div>
    </template>

    <a-modal
      :title="convertRuleTitle"
      v-model="convertRuleShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button type="primary" @click="setFieldConvertRule()">
            查询
          </a-button>
          <a-button type="primary" @click="addFieldConvertRule">
            新增
          </a-button>
        </div>
      </div>
      <a-table
        :columns="convertRuleTableColumns"
        :dataSource="convertRuleTableList"
        :pagination="false"
        :loading="convertRuleLoadingTable"
        size="middle"
      >
        <span slot="extraInfo" slot-scope="text, record">
          <template v-for="(value, key) in record.extraInfo">
            {{ extraInfoKeyDesc(key) }}: {{ value }}
          </template>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a href="javascript:;" @click="editFieldConvertRule(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteFieldConvertRule(record)"
          >
            <template slot="title">确认是否删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="convertRuleShow = false">关闭</a-button>
        </div>
      </template>

      <!-- 更新规则配置 -->
      <a-modal
        :title="updateFieldConvertRuleTitle"
        v-model="updateFieldConvertRuleShow"
        :maskClosable="false"
        class="action-class"
        width="500px"
      >
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-item label="转换类型:">
            <a-select
              v-model="convertType"
              placeholder="请选择类型"
              @change="convertTypeChange"
            >
              <a-select-option
                v-for="(item, index) in convertTypeList"
                :key="index"
                :value="item.code"
              >
                {{ item.desc }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <template v-if="convertType == 'SUBSTRING'">
            <a-form-item label="开始结束索引:">
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.beginIndex"
                placeholder="请输入开始索引"
                style="width: 45%; margin-right: 10px"
              />
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.endIndex"
                placeholder="请输入结束索引"
                style="width: 45%"
              />
            </a-form-item>
          </template>
          <template v-else-if="convertType == 'REPLACE'">
            <a-form-item label="文本替换旧值:">
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.replaceOld"
                placeholder="请输入文本替换旧值"
              />
            </a-form-item>
            <a-form-item label="文本替换新值:">
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.replaceNew"
                placeholder="请输入文本替换新值"
              />
            </a-form-item>
          </template>
          <template v-else-if="convertType == 'SPLIT'">
            <a-form-item label="分割取数分隔符:">
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.splitSeparator"
                placeholder="请输入分割取数分隔符"
              />
            </a-form-item>
            <a-form-item label="分割取数下标:">
              <a-input
                v-model="updateFieldConvertRuleExtraInfo.splitIndex"
                placeholder="请输入分割取数下标"
              />
            </a-form-item>
          </template>
        </a-form>
        <template slot="footer">
          <div style="display: flex; justify-content: center">
            <a-button key="back" @click="updateFieldConvertRuleShow = false"
              >取消</a-button
            >
            <a-button
              key="submit"
              type="primary"
              @click="updateFieldConvertRuleSubmit"
              >确定</a-button
            >
          </div>
        </template>
      </a-modal>
    </a-modal>
  </a-modal>
</template>

<script>
import * as api from "../lib/syncRuleList.js";
import { datasourcePage, showTables } from "../lib/dataSourceList.js";
export default {
  props: {
    title: String,
    show: {
      type: Boolean,
      default: false,
    },
    ruleId: Number,
    type: String,
    addInfo: {
      type: Object,
      default: function () {
        return;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nameAdd: "",
      sourceType: "MYSQL",
      sinkType: "MYSQL",
      sourceTypeList: ["MYSQL", "CLICKHOUSE", "KAFKA"],
      sinkTypeList: ["MYSQL", "CLICKHOUSE"],
      sinkDataSourceIdAdd: undefined,
      sourceTableNameAdd: "",
      sourceDataSourceIdAdd: undefined,
      sourceDatabaseNameAdd: "",
      sinkTableNameAdd: "",
      addKeyVisible: false,
      sourceDataSourceList: [],
      sinkDataSourceList: [],
      sourceTableList: [],
      sourceFiledList: [],
      sinkTableList: [],
      sinkFiledList: [],
      fieldMappingList: [],
      syncRuleFieldMappingColumns: [],
      searchTimer: null,
      ruleFieldId: "",
      convertRuleTableColumns: [],
      convertRuleTableList: [],
      convertRuleLoadingTable: false,
      convertRuleTitle: "",
      convertRuleShow: false,
      updateFieldConvertRuleTitle: "",
      updateFieldConvertRuleId: "",
      updateFieldConvertRuleShow: false,
      updateFieldConvertRuleExtraInfo: [],
      convertType: undefined,
      convertTypeList: [
        {
          code: "SUBSTRING",
          desc: "文本截取",
        },
        {
          code: "REPLACE",
          desc: "文本替换",
        },
        {
          code: "SPLIT",
          desc: "分割取数",
        },
      ],
      sourceTableSelect: true,
    };
  },
  computed: {
    extraInfoKeyDesc() {
      return (key) => {
        let desc = "";
        switch (key) {
          case "beginIndex":
            desc = "开始结束索引";
            break;
          case "endIndex":
            desc = "结束索引";
            break;
          case "replaceOld":
            desc = "文本替换旧值";
            break;
          case "replaceNew":
            desc = "文本替换新值";
            break;
          case "splitSeparator":
            desc = "分割取数分隔符";
            break;
          case "splitIndex":
            desc = "分割取数下标";
            break;
        }
        return desc;
      };
    },
    hasSourceField() {
      return (field) => {
        let arr = this.fieldMappingList.filter((item) => {
          return item.sourceField == field;
        });
        if (arr.length > 0) {
          return true;
        }
        return false;
      };
    },
    hasSinkField() {
      return (field) => {
        let arr = this.fieldMappingList.filter((item) => {
          return item.sinkField == field;
        });
        if (arr.length > 0) {
          return true;
        }
        return false;
      };
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => {
          this.syncRuleFieldMappingColumns.splice(0);
          if (newVal == "incr") {
            this.syncRuleFieldMappingColumns.push(
              ...[
                {
                  title: "原字段",
                  dataIndex: "sourceField",
                  scopedSlots: { customRender: "sourceField" },
                },
                {
                  title: "下游字段",
                  dataIndex: "sinkField",
                  scopedSlots: { customRender: "sinkField" },
                },
                {
                  title: "是否是唯一索引",
                  dataIndex: "uniqueIndex",
                  scopedSlots: { customRender: "uniqueIndex" },
                },
                {
                  title: "是否是合并字段",
                  dataIndex: "merge",
                  scopedSlots: { customRender: "merge" },
                },
                {
                  title: "操作",
                  key: "action",
                  fixed: "right",
                  align: "center",
                  scopedSlots: { customRender: "action" },
                },
              ]
            );
          } else {
            this.syncRuleFieldMappingColumns.push(
              ...[
                {
                  title: "原字段",
                  dataIndex: "sourceField",
                  scopedSlots: { customRender: "sourceField" },
                },
                {
                  title: "下游字段",
                  dataIndex: "sinkField",
                  scopedSlots: { customRender: "sinkField" },
                },
                {
                  title: "操作",
                  key: "action",
                  fixed: "right",
                  align: "center",
                  scopedSlots: { customRender: "action" },
                },
              ]
            );
          }
        });
      },
    },
    show: {
      handler(newVal) {
        if (newVal) {
          if (!this.ruleId) {
            this.sourceType = "KAFKA";
            this.sinkType = "CLICKHOUSE";
            this.nameAdd = "";
            this.sourceDatabaseNameAdd = "";
            this.sourceDataSourceIdAdd = undefined;
            this.sinkDataSourceIdAdd = undefined;
            this.sourceTableNameAdd = undefined;
            this.sinkTableNameAdd = undefined;
            if (this.type !== "template") {
              this.sourceType = this.addInfo.sourceType;
              this.sinkType = this.addInfo.sinkType;
              if (this.sourceType !== "KAFKA") {
                this.getDataSourceList(null, "source");
              }
            }

            this.fieldMappingList.splice(0);
            this.getDataSourceList(null, "sink");
            this.addKeyVisible = true;
          } else {
            api.detail({ syncRuleId: this.ruleId }).then((res) => {
              if (res.result == 200) {
                this.nameAdd = res.data.name;
                this.sourceType = res.data.syncType.split("_2_")[0];
                this.sinkType = res.data.syncType.split("_2_")[1];
                if (!res.data.sourceDataSourceId) {
                  this.sourceType = "KAFKA";
                }
                this.sourceDataSourceIdAdd = res.data.sourceDataSourceId;
                this.sourceDatabaseNameAdd = res.data.sourceDatabaseName;
                this.sourceTableNameAdd = res.data.sourceTableName;
                this.sinkDataSourceIdAdd = res.data.sinkDataSourceId;
                this.sinkTableNameAdd = res.data.sinkTableName;
                this.fieldMappingList.splice(0);
                this.fieldMappingList.push(...res.data.fieldMappingList);
                if (this.sourceType !== "KAFKA") {
                  this.getDataSourceList(null, "source");
                }
                this.getDataSourceList(null, "sink");
                this.addKeyVisible = true;
              }
            });
          }
        } else {
          this.addKeyVisible = false;
        }
      },
    },
    addKeyVisible: {
      handler(newVal) {
        if (!newVal) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    rowClassColor(record, index) {
      let className = "light-row";
      if (!record.sourceField || !record.sinkField) className = "dark-row";
      return className;
    },
    sourceTableTypeChange() {
      this.sourceTableSelect = !this.sourceTableSelect;
      this.sourceTableNameAdd = undefined;
      this.fieldMappingList.splice(0);
    },
    uniqueIndexChange(index) {
      if (!this.fieldMappingList[index].uniqueIndex) {
        this.$set(this.fieldMappingList[index], "merge", false);
      }
    },
    getDataSourceList(databaseName, type) {
      let data = {
        pageNo: 1,
        pageSize: 50,
        databaseName,
      };
      if (type == "source") {
        data.type = this.sourceType;
      } else {
        data.type = this.sinkType;
      }
      datasourcePage(data).then((res) => {
        if (res.result == 200) {
          if (type == "source") {
            this.sourceDataSourceList = res.data.records;
            if (this.sourceDataSourceIdAdd) {
              this.getTableList(this.sourceDataSourceIdAdd, "source");
            }
          } else {
            this.sinkDataSourceList = res.data.records;
            if (this.sinkDataSourceIdAdd) {
              this.getTableList(this.sinkDataSourceIdAdd, "sink");
            }
          }
        }
      });
    },
    dataSourceChange(datasourceId, type) {
      if (type == "source") {
        this.sourceDatabaseNameAdd = this.sourceDataSourceList.filter(
          (item) => {
            return datasourceId == item.id;
          }
        )[0].databaseName;
      }
      this.fieldMappingList.splice(0);
      this.getTableList(datasourceId, type);
    },
    searchDataSourceList(databaseName, type) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getDataSourceList(databaseName, type);
      }, 300);
    },
    getTableList(datasourceId, type) {
      let data = {
        datasourceId,
      };
      showTables(data).then((res) => {
        if (res.result == 200) {
          let tableName = "";
          if (type == "source") {
            this.sourceTableList = res.data;
            tableName = this.sourceTableNameAdd;
          } else {
            this.sinkTableList = res.data;
            tableName = this.sinkTableNameAdd;
          }
          if (type == "source") {
            let arr = this.sourceTableList.filter((item) => {
              return item == tableName;
            });
            if (arr.length == 0) {
              this.sourceTableSelect = false;
              return;
            }
          }
          if (tableName) {
            this.getTableFiledList(tableName, type);
          }
        }
      });
    },
    tableNameChange(tableName, type) {
      if (!this.sourceTableNameAdd) {
        return;
      }
      this.fieldMappingList.splice(0);
      this.getTableFiledList(tableName, type);
    },
    getTableFiledList(tableName, type) {
      let data = {
        databaseName: "",
        tableName,
      };
      if (type == "source") {
        data.datasourceId = this.sourceDataSourceIdAdd;
        data.databaseName = this.sourceDataSourceList.filter((item) => {
          return item.id == this.sourceDataSourceIdAdd;
        })[0].databaseName;
      } else {
        data.datasourceId = this.sinkDataSourceIdAdd;
        data.databaseName = this.sinkDataSourceList.filter((item) => {
          return item.id == this.sinkDataSourceIdAdd;
        })[0].databaseName;
      }
      api.showTablesField(data).then((res) => {
        if (res.result == 200) {
          if (type == "source") {
            this.sourceFiledList = res.data;
          } else {
            this.sinkFiledList = res.data;
          }
          if (
            this.sourceTableNameAdd &&
            this.sinkTableNameAdd &&
            this.fieldMappingList.length == 0
          ) {
            this.sinkFiledList.forEach((sinkField) => {
              let obj = {
                sourceField: undefined,
                sinkField,
                fieldConvertList: [],
              };
              if (this.type == "incr") {
                obj.uniqueIndex = false;
                obj.merge = false;
              }
              if (this.sourceType == "KAFKA") {
                obj.sourceField = sinkField;
              } else {
                this.sourceFiledList.forEach((sourceField) => {
                  if (sinkField == sourceField) {
                    obj.sourceField = sourceField;
                  }
                });
              }
              this.fieldMappingList.push(obj);
            });
          }
        }
      });
    },
    addSyncRuleFieldMapping() {
      let data = {
        sourceField: undefined,
        sinkField: undefined,
        fieldConvertList: [],
      };
      if (this.type == "incr") {
        data.uniqueIndex = false;
        data.merge = false;
      }
      this.fieldMappingList.push(data);
    },
    deleteSyncRuleFieldMapping(index) {
      this.fieldMappingList.splice(index, 1);
    },
    // 确定新增
    add_submit() {
      let arr = this.fieldMappingList.filter((item) => {
        return !item.sourceField || !item.sinkField;
      });
      if (arr.length > 0) {
        this.$message.warning("请填写全部字段");
        return;
      }
      let data = {
        name: this.nameAdd,
        sinkDataSourceId: this.sinkDataSourceIdAdd,
        sinkTableName: this.sinkTableNameAdd,
        sourceTableName: this.sourceTableNameAdd,
        fieldMappingList: this.fieldMappingList,
        sourceDatabaseName: this.sourceDatabaseNameAdd,
        sourceDataSourceId: this.sourceDataSourceIdAdd,
        syncType: this.sourceType + "_2_" + this.sinkType,
      };

      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.$emit("submit", res.data.id);
          }
        });
      } else {
        data.id = this.ruleId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.$emit("submit");
          }
        });
      }
    },
    setFieldConvertRule(val) {
      this.convertRuleLoadingTable = true;
      if (val) {
        this.ruleFieldId = val.id;
      }
      let data = {
        ruleFieldId: this.ruleFieldId,
      };
      api.fieldConvertRulePage(data).then((res) => {
        if (res.result == 200) {
          this.convertRuleTableList = res.data;
          this.convertRuleTableList.forEach((item) => {
            let convertTypeDesc = this.convertTypeList.filter((type) => {
              return type.code == item.convertType;
            })[0].desc;
            this.$set(item, "convertTypeDesc", convertTypeDesc);
            this.$set(item, "extraInfo", JSON.parse(item.extraInfo));
          });
          this.convertRuleTableColumns.splice(0);
          this.convertRuleTableColumns.push(
            ...[
              {
                title: "编号",
                dataIndex: "id",
                width: 80,
                ellipsis: true,
              },
              {
                title: "转换类型",
                dataIndex: "convertTypeDesc",
                ellipsis: true,
                width: 80,
              },
              {
                title: "其他信息",
                dataIndex: "extraInfo",
                scopedSlots: { customRender: "extraInfo" },
                ellipsis: true,
              },
              {
                title: "创建时间",
                dataIndex: "gmtCreated",
                width: 170,
                ellipsis: true,
              },
              {
                title: "操作",
                key: "action",
                fixed: "right",
                align: "center",
                width: 170,
                scopedSlots: { customRender: "action" },
              },
            ]
          );
          this.convertRuleLoadingTable = false;
          if (!this.convertRuleShow) {
            this.convertRuleTitle = val.sinkField + "转换规则配置";
            this.convertRuleShow = true;
          }
        }
      });
    },
    addFieldConvertRule() {
      this.updateFieldConvertRuleTitle = "新增";
      this.convertType = undefined;
      this.updateFieldConvertRuleExtraInfo = {};
      this.updateFieldConvertRuleShow = true;
    },
    editFieldConvertRule(val) {
      this.updateFieldConvertRuleTitle = "修改";
      this.updateFieldConvertRuleId = val.id;
      this.convertType = val.convertType;
      this.updateFieldConvertRuleExtraInfo = val.extraInfo;
      this.updateFieldConvertRuleShow = true;
    },
    convertTypeChange(convertType) {
      switch (convertType) {
        case "SUBSTRING":
          this.updateFieldConvertRuleExtraInfo = {
            beginIndex: "",
            endIndex: "",
          };
          break;
        case "REPLACE":
          this.updateFieldConvertRuleExtraInfo = {
            replaceOld: "",
            replaceNew: "",
          };
          break;
        case "SPLIT":
          this.updateFieldConvertRuleExtraInfo = {
            splitSeparator: ",",
            splitIndex: "",
          };
          break;
      }
    },
    updateFieldConvertRuleSubmit() {
      let data = {
        convertType: this.convertType,
        extraInfo: JSON.stringify(this.updateFieldConvertRuleExtraInfo),
        ruleFieldId: this.ruleFieldId,
      };
      if (this.updateFieldConvertRuleTitle == "新增") {
        api.addFieldConvertRule(data).then((res) => {
          if (res.result === 200) {
            this.updateFieldConvertRuleShow = false;
            this.$message.success("新增成功");
            this.setFieldConvertRule();
          }
        });
      } else {
        data.id = this.updateFieldConvertRuleId;
        api.editFieldConvertRule(data).then((res) => {
          if (res.result === 200) {
            this.updateFieldConvertRuleShow = false;
            this.$message.success("修改成功");
            this.setFieldConvertRule();
          }
        });
      }
    },
    deleteFieldConvertRule(val) {
      let data = {
        id: val.id,
      };
      api.deleteFieldConvertRule(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.setFieldConvertRule();
        }
      });
    },
  },
};
</script>
