import http from "./axios";

// 查询列表
export const datasourcePage = (data) => {
  return http.get("/datasource/datasourcePage", data);
};

export const showTables = (data) => {
  return http.get("/datasource/showTables", data);
};

// 添加
export const addData = (data) => {
  return http.json_post("/datasource/addDatasource", data);
};

// 编辑
export const editData = (data) => {
  return http.json_post("/datasource/editDatasource", data);
};

// 删除
export const deleteData = (data) => {
  return http.json_post("/datasource/deleteDatasource", data);
};

export const testConnect = (data) => {
  return http.get("/datasource/testConnect", data);
};
